import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as EyeIcon } from '../../../../assets/images/icon-eye.svg';
import { PASSWORD_INPUT } from '../../../../types/forms';
import Input, { IInputProps } from '../Input/Input';

import styles from './SecretInput.module.scss';

export interface IProps extends Omit<IInputProps, 'icon'> {
  isSecretPresent?: boolean;
  icon?: typeof EyeIcon;
  onClear?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SecretInput: React.FC<IProps> = ({
  icon,
  isSecretPresent = true,
  className,
  onBlur,
  onFocus,
  onClear,
  ...inputProps
}: IProps) => {
  const [showSecretValue, setShowSecretValue] = React.useState<boolean>(false);
  const defaultPlaceholder = '••••••••••••••••••••••••••••';

  const hasValue = React.useMemo(() => {
    const { field, value } = inputProps;
    return value === undefined || value === null ? !!field?.value : !!value;
  }, [inputProps]);

  const SecretIcon = React.useMemo(() => {
    return icon || EyeIcon;
  }, [icon]);

  const placeholder = React.useMemo(() => {
    switch (true) {
      case showSecretValue:
        return '';
      case hasValue || (!hasValue && isSecretPresent):
        return defaultPlaceholder;
      default:
        return inputProps.placeholder || '';
    }
  }, [hasValue, showSecretValue, isSecretPresent, inputProps.placeholder]);

  const handleToggleShowSecret = React.useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setShowSecretValue((value) => !value);
  }, []);

  const handleOnFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
      setShowSecretValue(true);
    },
    [onFocus],
  );

  const handleOnBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
      setShowSecretValue(false);
    },
    [onBlur],
  );

  return (
    <Input
      {...inputProps}
      type={isSecretPresent && !showSecretValue ? PASSWORD_INPUT : inputProps.type}
      placeholder={placeholder}
      className={cn(className, { [styles.hasSecret]: isSecretPresent })}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onClear={onClear}
      icon={
        hasValue ? (
          <SecretIcon
            onClick={handleToggleShowSecret}
            className={cn(styles.secretIcon, { [styles.activated]: showSecretValue })}
          />
        ) : null
      }
    />
  );
};

export default SecretInput;
