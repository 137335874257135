import cn from 'classnames';
import addYears from 'date-fns/addYears';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DownloadIcon from '../../assets/images/icon-download.svg';

import {
  DropdownWithContent,
  EngagementCard,
  Loader,
  NoResultsPlaceholder,
  PaginatedList,
  TabProvider,
} from '../../components';
import Filter from '../../components/Filter/Filter';
import {
  ActionButton,
  AsyncButton,
  DateRangePicker,
  ReportsCopyableButton,
  TextIconButton,
} from '../../components/forms';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../components/PaginatedList/PaginatedList';
import { MAX_REPORTS_PER_PAGE, REPORT_FILTERS, REPORT_TABS } from '../../constants/reports';
import { routes, URL_VARS } from '../../constants/routing';
import { DEPARTMENT_ID } from '../../constants/shipping';
import { ORG_ADMIN, SUPER_ADMIN, USER } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { toggleDownloadSuccessModal } from '../../store/actions/modals';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { downloadReportsCSVRequest, fetchReportsRequest } from '../../store/actions/reports';
import { fetchUsersRequest } from '../../store/actions/users';
import { selectAdminType, selectIsAdminTypeUser } from '../../store/selectors/auth';
import { selectBusinessReasons } from '../../store/selectors/reasons';
import {
  selectFilteredReports,
  selectIsDeliveryReportsFetching,
  selectIsDownloadingReportsCSV,
  selectIsFinalReport,
  selectReportsCount,
} from '../../store/selectors/reports';
import { selectUsers } from '../../store/selectors/users';
import {
  IDownloadReportsCSVRequestPayload,
  IReport,
  IReportsRequest,
  ReportsRequestTypesEnum,
  ReportTypesEnum,
} from '../../types/reports';
import {
  FilterTypesEnum,
  IApiError,
  IDatePickerOutput,
  IDownloadCSVSuccessPayload,
  IDropdownWithContentOptions,
  IHeaderConfig,
  IUseFilterSelectorContainerProp,
  NotificationListEnum,
  UISizeEnum,
} from '../../types/shell';
import notification from '../../utils/notification';
import { getReportCopyableInfo } from '../../utils/reports';
import { hasPermission } from '../../utils/users';

import styles from './ReportsContainer.module.scss';

interface IRenderDownloadDropDownProps {
  label: (options: IDropdownWithContentOptions) => React.ReactNode;
  contentClassName?: string;
}

const ReportsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setHeader, setAdditionalData: setHeaderAction } = useHeaderDispatch();

  const datePickerRef = React.useRef<HTMLButtonElement>(null);
  const paginatedListRef = React.useRef<IPaginatedListRef<IReport>>(null);

  const adminType = useSelector(selectAdminType);
  const isAdminTypeUser = useSelector(selectIsAdminTypeUser);
  const isDeliveryReportsFetching = useSelector(selectIsDeliveryReportsFetching);
  const isFinalReport = useSelector(selectIsFinalReport);
  const reasons = useSelector(selectBusinessReasons);
  const isDownloadingReportsCSV = useSelector(selectIsDownloadingReportsCSV);
  const users = useSelector(selectUsers);

  const { mobile, smallTablet } = useWindowSize();

  const [tab, setTab] = React.useState<ReportTypesEnum>(ReportTypesEnum.Regular);

  const [startRange, setStartRange] = React.useState<IDatePickerOutput['from']>(null);
  const [endRange, setEndRange] = React.useState<IDatePickerOutput['to']>(null);
  const [filter, setFilter] = React.useState(
    isAdminTypeUser
      ? REPORT_FILTERS.find(({ role = [] }) => role.includes(adminType))?.filterId
      : FilterTypesEnum.Individual,
  );

  const isIndividualFilter = React.useMemo(() => filter === FilterTypesEnum.Individual, [filter]);
  const dateRange = React.useMemo(() => ({ from: startRange || null, to: endRange || null }), [startRange, endRange]);
  const isScheduled = React.useMemo(() => tab === ReportTypesEnum.Scheduled, [tab]);

  const reports = useSelector(selectFilteredReports({ filter, type: tab }));
  const reportsCount = useSelector(selectReportsCount);

  const {
    DeptFilter,
    filteredItems: filteredReports,
    departments: selectedDepartments,
    setDepartments: setSelectedDepartments,
  } = useDeptFilter(reports || [], DEPARTMENT_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
    canOpen: !isDeliveryReportsFetching && filter === FilterTypesEnum.Organization,
  });

  const { SearchInput, query } = useSearchFilter([], [], {
    placeholder: 'Lookup sends',
    hint: 'Look up specific sends by their order ID, sender’s full name or email, or recipient’s full name or email.',
  });

  const filteredReportsByDate = React.useMemo(() => {
    const filterByDate = (fieldName: 'date_to_send' | 'created_at') => (array: IReport) => {
      return (startRange ? array[fieldName] >= startRange : true) && (endRange ? array[fieldName] <= endRange : true);
    };

    return filteredReports.filter(filterByDate(isScheduled ? 'date_to_send' : 'created_at'));
  }, [isScheduled, filteredReports, startRange, endRange]);

  const handleCopyBtnClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  const downloadReportsCSV = React.useCallback(
    (payload?: Partial<IDownloadReportsCSVRequestPayload>) => {
      dispatch(
        downloadReportsCSVRequest({
          ...(selectedDepartments && selectedDepartments.length
            ? {
                department_ids: Array.isArray(selectedDepartments) ? selectedDepartments : [selectedDepartments],
              }
            : null),
          isOrgSearch: filter === FilterTypesEnum.Organization,
          isDeptSearch: filter === FilterTypesEnum.Department,
          type: tab,
          range: dateRange,
          ...payload,
        }),
      );
    },
    [dispatch, selectedDepartments, filter, tab, dateRange],
  );

  const fetchReasons = React.useCallback(() => {
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (isAdminTypeUser && (!users || !users.length)) {
      dispatch(fetchUsersRequest());
    }
  }, [users, dispatch, isAdminTypeUser]);

  const fetchReports = React.useCallback(
    (payload: IReportsRequest) =>
      dispatch(
        fetchReportsRequest({
          body: {
            ...(selectedDepartments && selectedDepartments.length
              ? {
                  department_ids: Array.isArray(selectedDepartments) ? selectedDepartments : [selectedDepartments],
                }
              : null),
            ...(startRange && endRange
              ? {
                  date_range: {
                    from: startRange,
                    to: endRange,
                  },
                }
              : null),
            ...(query && { search_query: query }),
            ...payload,
          },
          isOrgSearch: filter === FilterTypesEnum.Organization,
          isDeptSearch: filter === FilterTypesEnum.Department,
          type: tab,
        }),
      ),
    [dispatch, filter, tab, selectedDepartments, startRange, endRange, query],
  );

  const handlePaginationChange = React.useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchReports({
        page,
        page_size: MAX_REPORTS_PER_PAGE,
      });
      // In case there's an error on the endpoint, paginator will change the page number, which is not correct.
      // The fix is to wrap the request with the Promise and call the `setPage(page)` after the Promise is resolved
      setPage(page);
    },
    [fetchReports],
  );

  const handleCardClick = React.useCallback(
    (uid: string | undefined) => {
      if (!uid) {
        return;
      }

      history.push(routes.reports.getReportsUrl({ reportId: uid, flowId: URL_VARS.SUMMARY, type: tab }));
    },
    [history, tab],
  );

  const handleDownloadClick = React.useCallback(
    (inventoryBreakdown: boolean) => {
      if (!startRange || !endRange) {
        notification.error(NotificationListEnum.DateRangeError, { content: 'Please, select dates' });

        datePickerRef?.current?.click();
        return;
      }

      const downloadReportsCSVPromise = new Promise<IDownloadCSVSuccessPayload>((resolve, reject) => {
        downloadReportsCSV({ resolve, reject, inventory_breakdown: inventoryBreakdown });
      }).then((response: IDownloadCSVSuccessPayload) => {
        dispatch(toggleDownloadSuccessModal(response));
      });

      notification.promise<void | IApiError>(NotificationListEnum.DownloadData, {
        promise: downloadReportsCSVPromise,
        promiseParams: {
          pending: 'Downloading process started. We’ll notify you when the file is ready!',
          error: {
            render: ({ data: error }) =>
              (error && error.message) || "Something bad has happened. CSV file wasn't created",
          },
        },
      });
    },
    [startRange, endRange, downloadReportsCSV, dispatch],
  );

  const filterConfig = React.useMemo(() => {
    return REPORT_FILTERS.filter(({ role = [] }) => !role.length || role.includes(adminType)).map(
      ({ filterId, label: optionLabel }) => {
        return {
          label: optionLabel,
          key: optionLabel,
          isActive: filterId === filter,
          onClick: () => {
            setFilter(filterId);
            setSelectedDepartments(null);
          },
        };
      },
    );
  }, [filter, setSelectedDepartments, adminType]);

  React.useEffect(() => {
    fetchReports({
      page: 1,
      page_size: MAX_REPORTS_PER_PAGE,
    });

    paginatedListRef.current?.resetPageCount();
  }, [filter, tab, selectedDepartments, fetchReports]);

  React.useEffect(() => {
    if (!reasons || !reasons.length) {
      fetchReasons();
    }
  }, [reasons, fetchReasons]);

  const renderDownloadDropDownOptions = React.useCallback(
    () => [
      <button
        key={ReportsRequestTypesEnum.Campaign}
        onClick={() => handleDownloadClick(false)}
        className={styles.dropdownButton}
      >
        Campaign Report
      </button>,
      <button
        key={ReportsRequestTypesEnum.Product}
        onClick={() => handleDownloadClick(true)}
        className={styles.dropdownButton}
      >
        Product Report
      </button>,
    ],
    [handleDownloadClick],
  );

  const renderDownloadDropDown = React.useCallback(
    ({ label, contentClassName }: IRenderDownloadDropDownProps) => (
      <DropdownWithContent label={label} contentClassName={cn(styles.dropdownContent, contentClassName)}>
        {renderDownloadDropDownOptions}
      </DropdownWithContent>
    ),
    [renderDownloadDropDownOptions],
  );

  const setMainHeader = React.useCallback(
    (headerInfo: Partial<IHeaderConfig>) => {
      if (typeof setHeader === 'function') {
        setHeader(headerInfo);
      }
    },
    [setHeader],
  );

  const setMobileHeader = React.useCallback(() => {
    if (!setHeaderAction) {
      return;
    }

    if (mobile) {
      const label = () => <TextIconButton icon={DownloadIcon} trigger />;

      setHeaderAction(
        isDownloadingReportsCSV ? (
          <AsyncButton loaderClassName={styles.spinner} disabled outlined className={styles.downloadBtn} isLoading />
        ) : (
          renderDownloadDropDown({
            label,
            contentClassName: styles.mobileContent,
          })
        ),
      );
    } else {
      setHeaderAction();
    }

    return setHeaderAction;
  }, [renderDownloadDropDown, mobile, setHeaderAction, isDownloadingReportsCSV]);

  React.useEffect(() => {
    setMainHeader({ title: 'Delivery Reports', action: () => history.push(routes.dashboard) });
  }, [setMainHeader, history]);

  React.useEffect(() => {
    return setMobileHeader();
  }, [setMobileHeader]);

  const tabIndex = React.useMemo(() => Object.values(ReportTypesEnum).indexOf(tab as ReportTypesEnum), [tab]);

  const getRenderTab = React.useCallback(
    (reportList: IReport[]) => {
      return !reportList || !reportList.length ? (
        <NoResultsPlaceholder />
      ) : (
        <div className={styles.list}>
          {reportList.map((report: IReport) => {
            const [firstCopyableInfo] = getReportCopyableInfo(report) || [];

            return (
              <EngagementCard
                key={report.uid}
                engagement={report}
                wide
                className={styles.card}
                controls={[
                  firstCopyableInfo ? (
                    <ReportsCopyableButton
                      className={styles.copyBtn}
                      key={`copy-button-${report.uid}`}
                      onClick={handleCopyBtnClick}
                      value={
                        Array.isArray(firstCopyableInfo.data)
                          ? firstCopyableInfo.data[0].url
                          : firstCopyableInfo.data.url
                      }
                    />
                  ) : null,
                  <ActionButton
                    className={styles.actionBtn}
                    title="View Summary"
                    outlined
                    size={UISizeEnum.Small}
                    key={`view-summary-${report.uid}`}
                    onClick={() => handleCardClick(report.uid)}
                  />,
                ]}
              />
            );
          })}
        </div>
      );
    },
    [handleCardClick, handleCopyBtnClick],
  );

  const setTabIndex = React.useCallback((index: number) => setTab(Object.values(ReportTypesEnum)[index]), []);

  const DeptFilterCustomContainer: React.FC<IUseFilterSelectorContainerProp> = React.useCallback(
    ({ children, reset, select }) => (
      <div className={styles.deptFilterContainer}>
        {children}
        <div className={styles.controls}>
          <ActionButton onClick={reset} title="Reset" outlined size={UISizeEnum.Small} />
          <ActionButton onClick={select} title="Select" size={UISizeEnum.Small} />
        </div>
      </div>
    ),
    [],
  );

  const handleSelectDateRange = React.useCallback(({ from: startDate, to: endDate }: IDatePickerOutput) => {
    setStartRange(startDate);
    setEndRange(endDate);
  }, []);

  return (
    <div className={cn(styles.reports)}>
      <div className={styles.filters}>
        {SearchInput}
        {isAdminTypeUser && (
          <div className={styles.adminFilters}>
            <Filter config={filterConfig} />
            {!isIndividualFilter &&
              hasPermission([ORG_ADMIN, SUPER_ADMIN], adminType) &&
              DeptFilter({
                selectContainer: DeptFilterCustomContainer,
                isMulti: true,
                defaultValue: null,
                limit: 10,
                onLimitReach: () =>
                  notification.warning(NotificationListEnum.RestrictionOnSelectionFromSelect, {
                    content: 'You can select only 10 departments at a time.',
                  }),
              })}
          </div>
        )}
        <div className={styles.downloads}>
          <>
            <DateRangePicker
              // there's few number filters for the user, so we need to shift the date-picker to the right
              pickerClassName={cn(styles.datePicker, { [styles.right]: hasPermission(USER, adminType) })}
              ref={datePickerRef}
              value={dateRange}
              onSelect={handleSelectDateRange}
              wide={!smallTablet}
              maxDate={addYears(new Date(), 10)}
            />
            {!mobile &&
              renderDownloadDropDown({
                label: () => (
                  <AsyncButton
                    disabled={isDownloadingReportsCSV}
                    className={styles.downloadBtn}
                    loaderClassName={styles.spinner}
                    isLoading={isDownloadingReportsCSV}
                  >
                    Download reports
                  </AsyncButton>
                ),
              })}
          </>
        </div>
      </div>
      <PaginatedList<IReport>
        totalCount={reportsCount}
        ref={paginatedListRef}
        items={filteredReportsByDate}
        pageSize={MAX_REPORTS_PER_PAGE}
        onChange={handlePaginationChange}
        isFinalItems={isFinalReport}
      >
        {({ items, pagination: Pagination }) => {
          return (
            <TabProvider
              className={styles.tabs}
              tabs={REPORT_TABS.map((tabItem) => ({
                ...tabItem,
                renderer: tab === tabItem.tabId ? getRenderTab(items) : null,
              }))}
              activeTab={tabIndex}
              onSelect={setTabIndex}
              disabled={isDeliveryReportsFetching}
            >
              <Pagination className={styles.pagination} />
              <Loader className={styles.loader} isLoading={isDeliveryReportsFetching} />
            </TabProvider>
          );
        }}
      </PaginatedList>
    </div>
  );
};

ReportsContainer.whyDidYouRender = false;

export default ReportsContainer;
