import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/images/icon-close-cross.svg';
import { updateLowItemsSnoozedAt } from '../../../store/actions/shell';
import { selectLowInventoryItems } from '../../../store/selectors/inventories';
import { selectLowInventoryModal } from '../../../store/selectors/modals';
import { selectLowItemsSnoozedAt } from '../../../store/selectors/shell';
import { BUTTON_BUTTON } from '../../../types/forms';
import { formatDate } from '../../../utils/date';
import { getIsLowInventoryItemsNotificationSnoozed, overrideTooltipPosition } from '../../../utils/helpers';
import { ActionButton, Checkbox } from '../../forms';
import { HelpTooltip, ModalActions, ModalHeader } from '../../index';
import LowInventoryItem from '../../widgets/LowItemsWidget/LowInventoryItem/LowInventoryItem';

import styles from './LowInventoryModal.module.scss';

interface IProps {
  onClose: () => void;
}

const LowInventoryModal: React.FC<IProps> = ({ onClose }: IProps) => {
  const dispatch = useDispatch();
  const lowInventoryNotificationSnoozedAt = useSelector(selectLowItemsSnoozedAt);
  const { metadata } = useSelector(selectLowInventoryModal);
  const lowInventoryItems = useSelector(selectLowInventoryItems);

  const shouldShowSnoozeControl = React.useMemo(() => metadata && metadata.canSnoozeNotification, [metadata]);

  const isLowInventoryNotificationSnoozed = React.useMemo(() => {
    return getIsLowInventoryItemsNotificationSnoozed(lowInventoryNotificationSnoozedAt);
  }, [lowInventoryNotificationSnoozedAt]);

  const handleSnoozeModal = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      const currentDate = formatDate(new Date().toDateString());
      dispatch(updateLowItemsSnoozedAt(checked ? currentDate : ''));
    },
    [dispatch],
  );

  return lowInventoryItems?.length ? (
    <div className={styles.container}>
      <ModalHeader
        className={styles.header}
        right={
          <button type={BUTTON_BUTTON} onClick={onClose}>
            <CloseIcon className={styles.closeModalButton} />
          </button>
        }
        titleClassName={styles.title}
        title="Inventory Running Low"
      />
      <p className={styles.description}>Some inventory is running low and requires your attention.</p>
      <div className={styles.modalContent}>
        {lowInventoryItems.map(
          ({
            name: itemName,
            price: itemPrice = 0,
            currency_code,
            product_sku: productSku = '',
            item_id: itemId,
            image_url: imageUrl,
            count: itemCount,
            type,
            preorder_allowed: isPreorder,
            low_stock_threshold: threshold,
          }) => {
            return (
              <LowInventoryItem
                key={itemId}
                itemId={itemId}
                title={itemName}
                price={itemPrice}
                currencyCode={currency_code}
                sku={productSku}
                type={type}
                iconUrl={imageUrl}
                count={itemCount}
                className={styles.lowItem}
                iconClassName={styles.lowItemImage}
                isPreorder={isPreorder}
                threshold={threshold}
              />
            );
          },
        )}
      </div>
      <ModalActions className={styles.modalFooter}>
        {shouldShowSnoozeControl && (
          <div className={styles.checkBoxContainer}>
            <Checkbox
              onChange={handleSnoozeModal}
              checked={isLowInventoryNotificationSnoozed}
              text="Snooze for 1 week"
            />
            <HelpTooltip
              id={`msku-tooltip-for-low-items-modal`}
              className={styles.modalSnoozeHintIcon}
              overridePosition={overrideTooltipPosition}
            >
              You can snooze this notification if the inventory is in transit.
            </HelpTooltip>
          </div>
        )}
        <ActionButton title="Close" className={styles.button} onClick={onClose} />
      </ModalActions>
    </div>
  ) : null;
};

export default LowInventoryModal;
