import {
  DIGITAL,
  LOW_COUNT_WARNING,
  MSKU,
  NO_COUNT_ERROR,
  ON_DEMAND,
  PHYSICAL,
  UNKNOWN_COUNT_VALUE,
  UNKNOWN_COUNT_WARNING,
} from '../constants/inventories';
import { PRINT_YOUR_CAUSE_FC_ID, PRINT_YOUR_CAUSE_UK_FC_ID } from '../constants/shipping';
import { ICampaign, ICampaignItem } from '../types/campaigns';
import {
  IGenericItemWithCustomization,
  IInventoryItem,
  IInventoryWithType,
  IOnDemandItem,
  IRequestOnDemandItem,
} from '../types/inventories';
import { IGenerateGiftSuggestResponse, IGenerateGiftSuggestSuccessPayload } from '../types/suggestedGift';
import { sortByNameAlphabeticallyAscending } from './helpers';

export const isItemDigital = (item: IInventoryWithType | null | undefined) => (item ? item.type === DIGITAL : false);
export const isItemMsku = (item: IInventoryWithType | null | undefined) => (item ? item.type === MSKU : false);
export const isItemPhysical = (item: IInventoryWithType | null | undefined) => (item ? item.type === PHYSICAL : false);
export const isItemOnDemand = (item: IInventoryItem | IOnDemandItem) => item?.hasOwnProperty(ON_DEMAND);

export const isCustomizableItem = ({ item_customization_allowed, sku_options }: IGenericItemWithCustomization) => {
  if (sku_options && sku_options?.length > 0) {
    return sku_options.some((item) => item.item_customization_allowed);
  }

  return item_customization_allowed;
};

export const isItemFromPYC = (item: { fulfillment_center_id: string } | null | undefined) =>
  item
    ? item.fulfillment_center_id === PRINT_YOUR_CAUSE_FC_ID || item.fulfillment_center_id === PRINT_YOUR_CAUSE_UK_FC_ID
    : false;

export const isSameFC = (
  items: { fulfillment_center_id: string; sku_options?: { fulfillment_center_id: string }[] }[] | undefined,
  uniqueFCs?: Set<string>,
) => {
  const fcs = uniqueFCs || new Set<string>();

  if (items) {
    items.forEach(({ fulfillment_center_id, sku_options }) => {
      fcs.add(fulfillment_center_id);

      if (sku_options?.length) {
        isSameFC(sku_options, fcs);
      }
    });
  }

  return fcs.size <= 1;
};

export const isBrokenMSKU = ({ fulfillment_center_id: parentFCID, sku_options }: Partial<IInventoryItem>): boolean => {
  return !!sku_options?.some(({ fulfillment_center_id: skuOptionFCID }) => skuOptionFCID !== parentFCID);
};

export const hasMskuItem = (items: IInventoryWithType[] | null | undefined) => items?.some(isItemMsku);

export const hasCustomizableItem = (items: (ICampaignItem | IOnDemandItem)[] | null | undefined) =>
  items?.some(isCustomizableItem);

export const checkPygHasMskuItem = (campaigns: ICampaign[] | null | undefined) => {
  return campaigns?.some(({ items }: ICampaign) => hasMskuItem(items));
};

/**
 * Calculates the total count of SKU options.
 *
 * @param {Pick<IInventoryItem, 'count' | 'preorder_allowed'>[] | undefined} skuOptions - The array of SKU options.
 * @return {number | undefined} The total count of SKU options, or undefined if there are preorder options.
 */
export const getSKUOptionsTotalCount = (
  skuOptions: Pick<IInventoryItem, 'count' | 'preorder_allowed'>[] | undefined,
): number | undefined => {
  const hasPreorder = skuOptions?.some((opt) => opt?.preorder_allowed);

  if (!hasPreorder) {
    return skuOptions
      ?.map((item) => (typeof item.count !== 'undefined' && item.count > 0 ? item.count : 0))
      .reduce((acc, val) => acc + val, 0);
  }
  return;
};

export const getMskuItemsMaxCount = (skuOptions: Pick<IInventoryItem, 'count'>[] | undefined) => {
  const countsArray = skuOptions?.map((item) => item.count || 0);

  if (countsArray && countsArray.length) {
    return Math.max(...countsArray);
  }
};

export const getMinInventoryItemCount = ({ sku_options, count }: Pick<IInventoryItem, 'count' | 'sku_options'>) => {
  if (sku_options && sku_options.length) {
    return Math.min(...sku_options.map((i) => (typeof i.count !== 'undefined' ? i.count : UNKNOWN_COUNT_VALUE)));
  }

  return typeof count !== 'undefined' ? count : UNKNOWN_COUNT_VALUE;
};

export const isItemUnderThreshold = ({
  sku_options,
  count,
  low_stock_threshold,
}: Pick<IInventoryItem, 'sku_options' | 'count' | 'low_stock_threshold'>): boolean => {
  if (sku_options && sku_options.length) {
    return sku_options.some(
      ({ count: optionCount, low_stock_threshold: optionThreshold }) =>
        optionCount && optionThreshold && optionCount < optionThreshold,
    );
  }

  return count && low_stock_threshold ? count < low_stock_threshold : false;
};

export const isItemCountUnknown = (item: Pick<IInventoryItem, 'count' | 'sku_options'>) =>
  getMinInventoryItemCount(item) === UNKNOWN_COUNT_VALUE;

export const isItemCountLow = (item: Pick<IInventoryItem, 'count' | 'sku_options' | 'low_stock_threshold'>) =>
  !isItemCountUnknown(item) && isItemUnderThreshold(item);

export const getItemCountWarning = (item: Pick<IInventoryItem, 'count' | 'sku_options' | 'low_stock_threshold'>) => {
  switch (true) {
    case isItemCountUnknown(item):
      return UNKNOWN_COUNT_WARNING;
    case (item.sku_options?.length ? getMskuItemsMaxCount(item.sku_options) : getMinInventoryItemCount(item)) === 0:
      return NO_COUNT_ERROR;
    case isItemCountLow(item):
      return LOW_COUNT_WARNING;
    default:
      return null;
  }
};

export const isItemOutOfStock = ({
  count,
  preorder_allowed,
  sku_options,
  type,
}: Pick<IInventoryItem, 'count' | 'preorder_allowed' | 'type'> & {
  sku_options?: Pick<IInventoryItem, 'count' | 'preorder_allowed' | 'type'>[];
}): boolean => {
  // Digital items are always in stock
  if (isItemDigital({ type })) {
    return false;
  }

  // Regular physical items are in stock if:
  //  - they have a count greater than 0
  //  OR
  //  - preorder is turned on
  if (!sku_options?.length) {
    return !((count && count > 0) || preorder_allowed);
  }

  // MSKU items are in stock if:
  //  - all of their options are in stock
  return sku_options.every(isItemOutOfStock);
};

export const getSortedInventoryItems = (items: IInventoryItem[] | undefined) => {
  if (items?.length) {
    return items.sort(sortByNameAlphabeticallyAscending);
  }

  return [];
};

export const hasMskuAnyOutOfStockOptions = (items: (ICampaignItem | IOnDemandItem)[] | null) => {
  return items?.some((item) => isItemMsku(item) && item.sku_options?.some(isItemOutOfStock));
};

export const hasPreorderAllowed = (items: (ICampaignItem | IOnDemandItem)[] | null) => {
  return items?.some(
    (position) =>
      position.preorder_allowed || position.sku_options?.some((inventoryItem) => inventoryItem.preorder_allowed),
  );
};

/**
 * Populates item IDs to OnDemandItems.
 *
 * @param {Array.<IRequestOnDemandItem> | undefined} items - The array of IRequestOnDemandItem.
 * @returns {Array.<IOnDemandItem>} - The array of OnDemandItems with populated item IDs.
 */
export const populateIDsToOnDemandItems = (items: IRequestOnDemandItem[] | undefined): IOnDemandItem[] => {
  if (items?.length) {
    return items.map((item) => ({ ...item, item_id: item.product_sku }));
  }

  return [];
};

export const mapAIGiftSuggestionsResponseToInventoryItems = (
  response: IGenerateGiftSuggestResponse,
): IGenerateGiftSuggestSuccessPayload => {
  const { suggested_gifts } = response;

  return {
    ...response,
    suggested_gifts: suggested_gifts.map(populateIDsToOnDemandItems),
  };
};
