import cn from 'classnames';
import * as React from 'react';
import { animated } from 'react-spring';

import ScaleInOut from '../../animations/ScaleInOut';
import { useCurrency } from '../../contexts/CurrencyProvider';
import useWindowSize from '../../hooks/useWindowSize';
import { BUTTON_BUTTON } from '../../types/forms';
import { IInventoryItem, InventoryTypesEnum } from '../../types/inventories';
import { ItemMetadata, Price } from '../index';

import styles from './InventoryItem.module.scss';

export interface IInventoryItemProps
  extends Omit<IInventoryItem, 'item_id' | 'department_ids' | 'description' | 'org_id' | 'name' | 'image_url'> {
  className?: string;
  itemId?: string;
  icon?: any;
  title?: string;
  price?: number;
  iconClassName?: string;
  infoClassName?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  onClick?: () => void;
  count?: number;
  isPreorder?: boolean;
  type: InventoryTypesEnum;
  isCustomizable?: boolean;
  skuOptions?: IInventoryItem[];
  onSelect?: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  hint?: string;
  threshold?: number;
}

const InventoryItem: React.FC<IInventoryItemProps> = ({
  className,
  itemId,
  icon,
  title,
  price,
  currency_code,
  iconClassName,
  infoClassName,
  onEdit,
  onDelete,
  onClick,
  count,
  isPreorder,
  isCustomizable,
  skuOptions,
  type,
  onSelect,
  isDisabled,
  isSelected,
  fulfillment_center_id,
  hint,
  threshold,
}: IInventoryItemProps) => {
  const { mobile } = useWindowSize();
  const { getItemPrice } = useCurrency();

  const itemDisplayedPrice = React.useMemo(
    () => getItemPrice({ price, type, sku_options: skuOptions, currency_code }),
    [type, price, skuOptions, currency_code, getItemPrice],
  );

  const onEditClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (typeof onEdit === 'function') {
        onEdit();
      }
    },
    [onEdit],
  );

  const onDeleteClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (typeof onDelete === 'function') {
        onDelete();
      }
    },
    [onDelete],
  );

  const handleSelectItem = React.useCallback(() => {
    if (!isDisabled && onSelect) {
      onSelect();
    }
  }, [isDisabled, onSelect]);

  const controlsMarkup = React.useMemo(() => {
    if ((onDelete || onEdit) && !onSelect) {
      return (
        <div className={cn(styles.controls, { [styles.mobile]: mobile })}>
          {onEdit && (
            <div role="button" className={cn(styles.controlButton, styles.edit)} onClick={onEditClick}>
              Edit
            </div>
          )}
          {onDelete && (
            <div role="button" className={cn(styles.controlButton, styles.delete)} onClick={onDeleteClick}>
              Delete
            </div>
          )}
        </div>
      );
    }

    return null;
  }, [onDelete, onEdit, mobile, onEditClick, onDeleteClick, onSelect]);

  return (
    <>
      <button
        className={cn(
          styles.inventoryItem,
          isDisabled ? styles.noHover : '',
          { [styles.pointer]: !!onClick },
          className,
        )}
        onClick={!isDisabled ? onClick : void 0}
        title={hint}
      >
        <div className={cn(styles.iconInner, iconClassName)}>
          <div
            className={cn(styles.icon, { [styles.disabled]: isDisabled })}
            style={{ backgroundImage: `url("${icon}")` }}
          />
          <ItemMetadata
            type={type}
            isPreorder={isPreorder}
            isCustomizable={isCustomizable}
            itemId={itemId}
            title={title}
            count={count}
            skuOptions={skuOptions}
            fulfillmentCenterId={fulfillment_center_id}
            threshold={threshold}
          />
          {onSelect && (
            <ScaleInOut trigger={!isDisabled}>
              {({ style }) => (
                <animated.div
                  role={BUTTON_BUTTON}
                  style={style}
                  className={cn(styles.addBtn, isSelected ? styles.selected : '', isDisabled ? styles.disabled : '')}
                  onClick={handleSelectItem}
                />
              )}
            </ScaleInOut>
          )}
        </div>
        {!mobile && controlsMarkup}
        <div className={cn(styles.info, infoClassName, { [styles.disabled]: isDisabled })}>
          <div className={styles.titleAndPrice}>
            <Price value={itemDisplayedPrice} className={styles.price} />
            <div className={styles.title} title={title}>
              {title}
            </div>
          </div>
          {mobile && controlsMarkup}
        </div>
      </button>
    </>
  );
};

export default InventoryItem;
